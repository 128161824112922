export const menuTypes =    [
    {
      value: 7,
      label: "Contact Form",
    },
    {
      value: 6,
      label: "FAQ",
    },
    {
      value: 2,
      label: 'Link or file',
    },
    {
      value: 4,
      label: "Listing",
    },
    {
      value: 11,
      label: "Map Points",
    },
    {
      value: 13,
      label: "Page with gallery",
    },
    {
      value: 3,
      label: 'Phone',
    },
    {
      value: 1,
      label: "Simple page",
    },
    {
      value: 5,
      label: "Submenu",
    },
    {
      value: 14,
      label: "Trails",
    },
    {
      value: 10,
      label: "Weather",
    },
  ];

