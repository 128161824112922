<template>
  <CModal
    @update:show="closeModal"
    :title="`${eventTypes[event]} template`"
    color="primary"
    :show="showModal"
    centered
  >
    <CInput label="Name" placeholder="Enter your name" v-model="item.name" />
    <CTextarea
      label="Description"
      placeholder="Enter your description"
      v-model="item.description"
    />
    <CInputFile
      label="Images"
      multiple
      placeholder="Add your images"
      @change="addFile"
    />
    <CSelect
      label="Menu type"
      :options="types"
      :value.sync="item.type"
      @update:value="changeType"
    ></CSelect>
    <vue-json-editor v-model="item.content" :expandedOnStart="true" ></vue-json-editor>
  </CModal>
</template>

<script>
import {
  CModal,
  CInput,
  CTextarea,
  CInputFile,
  CSelect,
} from "@coreui/vue/src";
import OptionsService from "@/services/OptionsService";
import { menuTypes } from "@/helpers/menuTypes";
import VueJsonEditor from 'vue-json-editor';
export default {
  name: "MenuTemplateModal",
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default() {
        return {
          name: "default name",
        };
      },
    },
    event: {
      type: String,
      default: "create",
    },
  },
  components: {
    CInput,
    CModal,
    CTextarea,
    CInputFile,
    CSelect,
    VueJsonEditor
  },
  computed: {},
  data() {
    return {
      images: [],
      types: menuTypes,
      eventTypes: {
        create: "Create",
        edit: "Edit",
      },
    };
  },
  methods: {
    changeType(type) {
      this.item.type = type;
    },
    async addFile(files) {
      for (let i = 0; i < files.length; i++) {
        try {
          const { data } = await OptionsService.postImage(files[i]);
          this.item.images.push(data);
        } catch (e) {
          console.log(e);
        }
      }
    },
    closeModal(show, event, accept) {
      if (accept) {
        this.$emit("accept");
      } else {
        this.$emit("close");
      }
    },
  },
};
</script>

<style scoped></style>
