var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CContainer',{staticClass:"mainTableContainer",attrs:{"fluid":""}},[_c('CRow',{attrs:{"alignVertical":"center","alignHorizontal":"center"}},[_c('CCol',{staticClass:"mainTableColumn",attrs:{"lg":"12"}},[_c('CButton',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.createTemplate()}}},[_vm._v("Create")])],1),_c('CCol',{staticClass:"mainTableColumn",attrs:{"lg":"12"}},[_c('CDataTable',{attrs:{"noItemsView":{
          noResults: 'no items found',
          noItems: 'no items',
        },"hover":true,"striped":true,"items-per-page":20,"columnFilter":"","sorter":"","pagination":"","items":_vm.getMenuTemplates,"clickableRows":true,"loading":_vm.loading,"fields":_vm.fields,"size":"sm","tableFilterValue":_vm.filter},on:{"update:tableFilterValue":function($event){_vm.filter=$event},"update:table-filter-value":function($event){_vm.filter=$event},"row-clicked":_vm.openTemplateDetail},scopedSlots:_vm._u([{key:"templates",fn:function(ref){
        var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(item.templates.length)+" ")])]}},{key:"options",fn:function(ref){
        var item = ref.item;
return [_c('td',[_c('Pencil',{attrs:{"size":20},on:{"click":function($event){$event.stopPropagation();return _vm.editItem(item)}}}),_c('Delete',{attrs:{"size":20},on:{"click":function($event){$event.stopPropagation();return _vm.deleteItemShow(item.id)}}})],1)]}}])})],1)],1),_c('DeleteConfirmModal',{attrs:{"show":_vm.showDelete},on:{"close":_vm.hideDelete,"accept":_vm.deleteItemConfirm}}),(_vm.showModal)?_c('MenuTemplateModal',{attrs:{"item":_vm.template,"show-modal":_vm.showModal,"event":_vm.event},on:{"accept":_vm.confirmTemplate,"close":_vm.closeModal}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }